import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//element-plus 解决消息框组件样式丢失
import 'element-plus/theme-chalk/el-message.css';
import 'element-plus/theme-chalk/el-notification.css';
//axios
import axios from './api'

const app = createApp(App)
app.config.globalProperties.$api = '../api/index'
app.config.globalProperties.$axios = axios
app.use(store)
app.use(router)
app.mount('#app')