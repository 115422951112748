import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  var _component_el_config_provider = _resolveComponent("el-config-provider");

  return _openBlock(), _createBlock(_component_el_config_provider, {
    locale: $setup.locale,
    size: $setup.size
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_router_view, null, {
        default: _withCtx(function (_ref) {
          var Component = _ref.Component;
          return [_createVNode(_Transition, {
            name: "slide-left"
          }, {
            default: _withCtx(function () {
              return [(_openBlock(), _createBlock(_resolveDynamicComponent(Component)))];
            }),
            _: 2
          }, 1024)];
        }),
        _: 1
      })];
    }),
    _: 1
  }, 8, ["locale", "size"]);
}