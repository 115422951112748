import _defineProperty from "/Users/peakchao/Code/Web/apiopen-v2/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.function.name.js";
import locale from "element-plus/lib/locale/lang/zh-cn";
import { ElConfigProvider } from "element-plus";
export default {
  name: "App",
  components: _defineProperty({}, ElConfigProvider.name, ElConfigProvider),
  setup: function setup() {
    return {
      locale: locale,
      size: "small"
    };
  }
};