import { isLogin } from '@/util/EnvUtil'
import { createRouter, createWebHistory, RouteLocation, RouteLocationNormalized, RouteLocationNormalizedLoaded, RouteRecordRaw } from 'vue-router'

/**
 * RouteMeta 类型约束
 */
declare module 'vue-router' {
  interface RouteMeta {
    isAuth?: boolean
    title: string
  }
}

/**
 * 路由组
 */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import(/* webpackChunkName: "group-home" */'../views/Home.vue')
  },
  {
    path: '/v1',
    name: 'V1',
    meta: { title: 'v1接口' },
    component: () => import(/* webpackChunkName: "group-home" */'../views/V1.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '登录' },
    component: () => import(/* webpackChunkName: "group-home" */'../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    meta: { title: '注册' },
    component: () => import(/* webpackChunkName: "group-home" */'../views/Register.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    redirect: '/welcome',
    component: () => import(/* webpackChunkName: "group-admin" */'../views/Admin.vue'),
    meta: { isAuth: true, title: '后台管理' },
    children: [
      {
        path: '/welcome',
        name: 'Welcome',
        meta: { title: '后台管理' },
        component: () => import('../components/Welcome.vue')
      },
      {
        path: '/config',
        name: 'Config',
        meta: { title: '基础配置' },
        component: () => import('../components/Config.vue')
      },
      {
        path: '/users',
        name: 'Users',
        meta: { title: '用户列表' },
        component: () => import('../components/Users.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: '关于我' },
    component: () => import(/* webpackChunkName: "group-home" */'../views/About.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    meta: { title: '页面未找到' },
    component: () => import(/* webpackChunkName: "group-home" */'../views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded, savedPosition) {
    return { top: 0 }
  },
})

/**
 * 前置路由守卫
 */
router.beforeEach((to: RouteLocation, from: RouteLocation) => {
  if (to.matched.length !== 0) {
    //验证页面权限to.matched.some(record => record.meta.isAuth)
    if (to.meta.isAuth && !isLogin()) {
      router.replace({ path: '/login', params: { formPath: to.fullPath } })
      return
    } else {
      return true
    }
  } else {
    router.replace({ name: '404', params: { formPath: to.fullPath } })
    return
  }
})

/**
 * 后置路由守卫
 */
router.afterEach(((to: RouteLocation, from: RouteLocation) => {
  document.title = '开放API-2.0' + (to.meta.title ? `-${to.meta.title}` : '');
}))

export default router
