const isDev = process.env.NODE_ENV === "development"
const isAdmin = () => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo") || "")
    return userInfo.level === 1
}

const isLogin = () => {
    return !!localStorage.getItem("userInfo")
}

export { isDev, isAdmin, isLogin }