import { createStore } from 'vuex'

const moduleA = {
  state: {},
  mutations: {},
  actions: {
  }
}

const moduleB = {
  state: {},
  mutations: {},
  actions: {}
}

export default createStore({
  //可以理解为和data属性一样，存储一些全局公用的变量 调用方法：store.state.count
  state: {
    count: 0
  },

  //有时候我们需要从 store 中的 state 中派生出一些状态，例如对列表进行过滤并计数 调用方法：store.getters.getCount
  getters: {
    getCount: (state) => (par: number) => {
      return state.count + par
    }
  },

  //主要是定义同步方法，并且推荐state中的变量由该方法进行更改，方便数据跟踪，调用方法：this.$store.commit('xxx', params)
  mutations: {
    increment(state, payload) {
      state.count = payload.newCount ? state.count + payload.newCount : state.count++
    }
  },

  //主要是定义异步方法，调用方法：this.$store.dispatch('xxx', params)
  actions: {
    // addCount(context) {
    //   context.commit('increment')
    // }
    addCount({ commit }) {
      let action = {
        type: 'increment',
        newCount: 666
      }
      commit(action)
    }
  },

  //定义子模块，每个子模块也会有state、getters、mutations、actions、modules
  modules: {
    a: moduleA,
    b: moduleB
  },
})
