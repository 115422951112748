import { isDev } from '@/util/EnvUtil';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from "element-plus";
import router from '../router'

axios.interceptors.request.use((config: AxiosRequestConfig<any>) => {
    config.headers!.Authorization = `token`;
    config.headers!.token = localStorage.getItem("token") || ""
    isDev && console.log('发起请求-->', config.url, config.headers);
    return config
})

axios.interceptors.response.use((config: AxiosResponse) => {
    let { data, statusText } = config
    isDev && console.log('响应请求-->', data);
    if (data) {
        if (data.code === 200) {
            return data
        } else if (data.code === 401) {
            router.replace("/login");
            return Promise.reject(data)
        } else {
            ElMessage.error(data.message);
            return Promise.reject(data)
        }
    } else {
        ElMessage.error(statusText);
        return Promise.reject(statusText)
    }
}, error => {
    const message = error.response && error.response.data.message || error.response.data
    ElMessage.error(message);
    return Promise.reject(message)
})

export default axios